<template>
  <div class="info" id="watermark">
    <top-bar :title="'疫情隔离'" :left="true"></top-bar>
    <van-tabs sticky @click="checkTabs">
      <van-tab :title="'基本信息'">
        <!-- 性别 -->
        <van-popup v-model="sexShow"   position="bottom"  >
          <van-picker title="请选择" show-toolbar :columns="selectData.sexList" value-key="label"  @confirm="sexConfirm"  @cancel="sexShow = false"/>
        </van-popup>

        <!-- 隔离时间 -->
        <van-popup v-model="isolateTimeShow" position="bottom">
          <van-datetime-picker v-model="submitData.isolateTime" type="date" title="请选择" :formatter="formatDate"
                               @cancel="isolateTimeShow = false" @confirm="isolateTimeConfirm" :maxDate="maxDate"/>
        </van-popup>

        <!--  隔离人员类型  -->
        <van-popup v-model="userTypeShow"   position="bottom"  >
          <van-picker title="请选择" show-toolbar :columns="selectData.userTypeList" value-key="label"  @confirm="userTypeConfirm"  @cancel="userTypeShow = false"/>
        </van-popup>

        <!-- 所在小区 -->
        <van-popup v-model="CommunityShow"  position="bottom"  >
          <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
        </van-popup>

        <!-- 居住房屋 -->
        <van-popup v-model="buildingShow" position="bottom">
          <van-cascader v-model="submitData.building" title="请选择" :options="selectData.buildingList"
                        @close="buildingShow = false"  @change="buildingChange" @finish="buildingFinish" :field-names="fieldNames"/>
        </van-popup>

        <van-cell-group>
          <van-cell>
            <template #title>
              <div class="blue-box"></div>
              <span class="custom-title">基本信息</span>
            </template>
          </van-cell>
          <van-cell title="居住小区" :value="selectMsg.Community" @click="CommunityShow = edit" is-link :value-class="{'value-common':selectMsg.Community=='请选择'}"/>
          <van-cell title="居住房屋" :value="selectMsg.buildingStr" @click="buildingShow = edit" is-link :value-class="{'value-common':selectMsg.buildingStr=='请选择'}"/>
          <van-cell><van-field :clearable="!edit" clear-trigger="always" v-model="submitData.idNumber" label="身份证号" placeholder="请输入" input-align="right" @blur="bindInput" :readonly="!edit"/></van-cell>
          <van-cell><van-field :clearable="!edit" clear-trigger="always" v-model="submitData.userName" label="姓名" placeholder="请输入" input-align="right" :readonly="!edit"/></van-cell>
          <van-cell title="性别" :value="selectMsg.sexStr" @click="sexShow = edit" is-link :value-class="{'value-common':selectMsg.sexStr=='请选择'}"/>
          <van-cell title="隔离时间" :value="selectMsg.isolateTime" @click="isolateTimeShow = edit" is-link :value-class="{'value-common':selectMsg.isolateTime=='请选择'}"/>
          <van-cell title="隔离人员类型" :value="selectMsg.userTypeStr" @click="userTypeShow = edit" is-link :value-class="{'value-common':selectMsg.userTypeStr=='请选择'}"/>
          <van-cell><van-field :clearable="!edit" clear-trigger="always" v-model="submitData.mobile" label="手机号码" placeholder="请输入" input-align="right" :readonly="!edit"/></van-cell>
          <van-cell><van-field :clearable="!edit" clear-trigger="always" v-model="submitData.emContact" label="紧急联系人" placeholder="请输入" input-align="right" :readonly="!edit"/></van-cell>
          <van-cell><van-field :clearable="!edit" clear-trigger="always" v-model="submitData.emMobile" label="联系人手机号" placeholder="请输入" input-align="right" :readonly="!edit"/></van-cell>
          <van-cell title="头像" is-link>
            <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead" :deletable="edit"
                          :before-read="beforeUpload" :show-upload="edit" :max-count="1">
              <div v-if="submitData.headImage == ''">上传文件</div>
            </van-uploader>
          </van-cell>
        </van-cell-group>
        <van-row class="btns">
          <van-col :span="24">
            <van-button type="info" size="large" round @click="updateChange" v-if="!edit">修改</van-button>
            <van-button type="info" size="large" round @click="submit" v-if="edit">确认提交</van-button>
          </van-col>
        </van-row>
      </van-tab>
      <van-tab :title="'检查记录'">
        <div class="cont">
          <van-list @load="getUserInfo" v-if="checklist.length > 0" offset="10">
            <van-cell v-for="item in checklist" :key="item.id">
              <div class="item">
                <van-cell-group :border="false">
                  <van-cell :title="'上报时间：'" class="title" center>
                    <span>{{ item.observationDate }}</span>
                  </van-cell>
                  <van-cell class="itemCont" :title="'体温：'">
                    <span>{{ item.temperature }}℃</span>
                  </van-cell>
                  <van-cell class="itemCont" :title="'发烧：'">
                    <span>{{ item.fever?'有':'无' }}</span>
                  </van-cell>
                  <van-cell class="itemCont" :title="'咳嗽：'">
                    <span>{{ item.cough?'有':'无' }}</span>
                  </van-cell>
                  <van-cell class="itemCont" :title="'其他症状：'">
                    <span>{{ item.symptoms }}</span>
                  </van-cell>
                </van-cell-group>
              </div>
            </van-cell>
          </van-list>
          <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
        </div>
      </van-tab>

      <van-tab :title="'照片上传记录'">
        <div class="cont" v-if="pictureList.length > 1">
          <van-steps direction="vertical" :active="0" v-for="item in pictureList" :key="item.id">
            <van-step>
              <h3>{{item.reportTime}}</h3>
              <van-image :src="item.image" width="3rem" height="3rem" fit="fill" @click="imgPreView(item.image)"></van-image>
            </van-step>
          </van-steps>
        </div>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDate, getBirthdayFromIdCard, getSexFromIdCard, IdentityCodeValid} from '@/utils/utils'
import {isNumber} from '@/utils/validate'
import {getImageStream} from '@/utils/index'
import { ImagePreview } from 'vant';

export default {
  components: {
    topBar,
  },
  data() {
    return {
      edit: false,
      // 性别选择
      sexShow: false,
      // 出生日期选择
      birthTimeShow: false,
      // 隔离时间选择
      isolateTimeShow: false,
      // 隔离人员类型选择
      userTypeShow: false,
      // 所在小区选择
      CommunityShow: false,
      // 居住房屋选择
      buildingShow: false,
      // 和户主关系选择
      relationshipShow: false,
      // 户籍性质
      registryTypeShow: false,
      selectMsg: {
        //性别
        sexStr: '女',
        //出生
        birthday: '',
        //隔离时间
        isolateTime: '',
        //隔离人员
        userTypeStr: '请选择',
        //所在小区
        Community: '请选择',
        //居住房屋
        buildingStr: '请选择',
        //和户主关系
        relationshipStr: '请选择',
        //户籍性质
        registryTypeStr: "请选择",
        //国籍
        nationalityStr: '中国',
        //民族
        nationStr: '汉族',
      },
      submitData: {
        id: 0,
        orgId:0,
        //手机号码
        mobile: '',
        //姓名
        userName: '',
        //身份证号
        idNumber: '',
        //国籍
        nationality: '59',
        //民族
        nation: '2',
        //性别
        sex: '2',
        //出生
        birthday: '',
        //隔离时间
        isolateTime: '',
        //隔离人员
        userType: '',
        //工作单位
        company: '',
        //户籍地址
        cardAdd: '',
        //现居地
        nowAddress: '',
        //所在小区
        subarea:'',
        //居住房屋
        houseId: '',
        //和户主关系
        relationship: '',
        //户籍性质
        registryType: '',
        //紧急联系人
        emContact: '',
        //联系人手机号
        emMobile: '',
        userId:'',
        //头像
        headImage: '',
        suffix: '',
        //证件类别
        idNumberType: '1',
        //用户隔离表id
        isolationId: '',
        //用户居住表
        userResId:'',
        //社区id
        communityId:'',
      },
      selectData: {
        sexList: [{value: '2', label: '女'}, {value: '1', label: '男'}],
        userTypeList: [{value: '1', label: '国内中高风险市低风险区进入'}, {value: '2', label: '境外进入'}],
        CommunityList: [],
        buildingList: [],
        relationshipList: [],
        registryTypeList: []
      },
      resHouses: [{
        subArea: '',
        houseId: '',
        relationship: '',
      }],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      maxDate: '',
      fileList: [],
      userInfo: {
        page: 0,
        limit: 10,
        loading: false,
        finished: false
      },
      checklist: [],
      pictureList: [],
      // list: []
    }
  },
  methods: {
    checkTabs(name, value) {
      switch (name) {
        case 0:
          this.init();
          break;
        case 1:
          this.userInfo.page = 0;
          this.userInfo.limit = 10;
          // this.getUserInfo();
          break;
        case 2:
          break;
        case 3:
          // this.visitForm.page = 0;
          break;
      }
    },
    init() {
      let nowDate = new Date()
      this.maxDate = nowDate
      this.edit = false
      this.getSubArea()
      this.$nextTick(() => {
        this.getUserInfo()
        this.getPictureList()
      })
    },
    getSubArea(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.CommunityList = data.subAreaList
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    //照片列表
    getPictureList(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/isolation/res/report/list'),
        method: 'post',
        params: this.$http.adornParams({
          isolationId: this.submitData.id
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.pictureList = data.page.list
          this.pictureList.map(item => {
            item['image'] = getImageStream(item.path)
          })
          // this.selectData.CommunityList = data.subAreaList
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getUserInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/res/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 0,
          limit: 10,
          userId: this.submitData.userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.page.list[0]
          this.checklist = list.isolationObservations
          this.selectMsg.Community = list.subareaName
          this.submitData.subarea = list.subarea
          this.selectMsg.buildingStr = list.houseName
          this.submitData.idNumber = list.idNumber
          this.submitData.userName = list.userName
          this.selectMsg.isolateTime = list.isolationDate
          this.submitData.isolateTime = list.isolationDate
          this.submitData.mobile = list.mobile
          this.submitData.emContact = list.emContact == 'null' ? '' : list.emContact
          this.submitData.emMobile = list.emMobile == 'null' ? '' : list.emMobile
          this.submitData.headImage = list.headImg
          this.submitData.isolationId = list.id
          this.submitData.userResId = list.userResId
          this.submitData.userId = list.userId
          this.submitData.userType = list.userType + ''
          this.selectMsg.userTypeStr = list.userType == 1? '国内中高风险市低风险区进入' : '境外进入'
          this.submitData.houseId = list.houseId
          this.submitData.communityId = list.community
          let files = this.submitData.headImage
            let urls = []
            let file = files
            let u
            u = getImageStream(file)
            let url = {
              uid: urls.length,
              id: file.id,
              status: 'done',//uploading上传中，done上传完成，error上传失败
              fileType: 1,
              url: u,
              realUrl: file.replace(/\\/g, "/")
            }
            urls.push(url);
          this.fileList = urls
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    submit () {
      if (isNumber(this.submitData.mobile) === false) {return this.$toast.fail('失败:手机号必须为数字');}
      if (IdentityCodeValid (this.submitData.idNumber) === false) {return this.$toast.fail('失败:身份证号格式错误');}
      if ( this.selectMsg.isolateTime === '请选择') {return this.$toast.fail('失败:请先选择隔离时间');}
      if ( this.submitData.userName === '') {return this.$toast.fail('失败:请先输入姓名');}
      if ( this.submitData.idNumber === '') {return this.$toast.fail('失败:请先输入身份证号');}
      if ( this.selectMsg.userTypeStr === '请选择') {return this.$toast.fail('失败:请先选择隔离人员类型');}
      if (this.submitData.houseId === '') {return this.$toast.fail('失败:请先选择居住房屋');}
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/res/update/wxapp'),
        method: 'post',
        data : this.$http.adornParams({
          id: this.submitData.id,
          community: this.resHouses[0].subArea ? this.resHouses[0].subArea : this.submitData.communityId,
          houseId: this.resHouses[0].houseId ? this.resHouses[0].houseId : this.submitData.houseId,
          userId: this.submitData.userId,
          userType: Number(this.submitData.userType),
          idNumber: this.submitData.idNumber,
          userName: this.submitData.userName,
          sex: Number(this.submitData.sex),
          mobile: this.submitData.mobile,
          headImg: this.submitData.headImage,
          userResId: this.submitData.userResId,
          emMobile: this.submitData.emMobile,
          emContact: this.submitData.emContact,
          isolationDate: this.submitData.isolateTime
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success({
            message: '修改成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    updateChange () {
      this.edit = true
    },
    //身份证输入框事件
    bindInput (e) {
      let value = e.currentTarget.value
      if (value.length == 18 || value.length == 15) {
        this.selectMsg.birthday = getBirthdayFromIdCard(value)
        this.submitData.birthday = getBirthdayFromIdCard(value)
        this.selectMsg.sexStr = getSexFromIdCard(value) == 1 ? '男' : '女'
        this.submitData.sex = getSexFromIdCard(value) + ''
      }
    },
    // 删除图片
    uploaddel(file, detail) {
      this.submitData.headImage = ''
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/inspectionPicture/temporary')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.submitData.headImage = data.fileMessage.relativePath
            this.submitData.suffix = data.fileMessage.suffix
            console.log(this.submitData.headImage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    imgPreView (path) {
      let index
      let list = []
      this.pictureList.forEach((item, i) => {
        if (item.image == path) {
          index = i
          list.push(item.image)
        }
      })
      ImagePreview({
        images: list,
        startPosition: 0,
        closeable: true,
      });
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
    },
    // 小区选择
    CommunityConfirm (value) {
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        // // 获取房屋列表
        // getBuildingList() {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
          method: 'get',
          params: this.$http.adornParams({
            id: this.submitData.subarea,
            depth: 1,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.selectData.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
        // },
      }
      this.CommunityShow = false
    },
    // 居住房屋选择
    buildingChange (value, index) {
      if (this.submitData.subarea === '') {
        this.$toast.fail('请先选择小区!')
      } else {
        if (value.value && value.tabIndex == 0) {
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
            method: 'get',
            params: this.$http.adornParams({
              id: value.value,
              depth: 2,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (data.liveWhichTree.length > 0) {
                this.selectData.buildingList.map(item => {
                  if (value.value == item.value) {
                    item.children = data.liveWhichTree
                  }
                })
                this.buildingShow = true
              }
            } else {
              this.$toast.fail(data.msg)
            }
          })
        } else if (value.value && value.tabIndex == 1){
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
            method: 'get',
            params: this.$http.adornParams({
              id: value.value,
              depth: 3,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (data.liveWhichTree.length > 0) {
                this.selectData.buildingList.map(item => {
                  if (item.children) {
                    item.children.map(items => {
                      if (value.value == items.value) {
                        items.children = data.liveWhichTree
                      }
                    })
                  }
                  this.buildingShow = true
                })
                this.buildingShow = true
              }
            } else {
              this.$toast.fail(data.msg)
            }
          })
        }
      }
    },
    // 居住房屋完成
    buildingFinish(value) {
      if (value) {
        let options = value.selectedOptions
        this.selectMsg.buildingStr = ''
        let houseId = -1;
        let option = options[options.length - 1];
        if (option.depth == 2 && option.children == null) {
          houseId = option.value
        } else if (option.depth == 3 && option.children == null) {
          houseId = -1
        } else if (option.depth == 4 && option.children == null) {
          houseId = option.value
        } else {
          houseId = -1
        }
        this.submitData.houseId = houseId
        options.map(item => {
          this.selectMsg.buildingStr += item.label
        })
      }
      this.buildingShow = false
    },
    // 性别选择
    sexConfirm (value) {
      if(value) {
        this.selectMsg.sexStr = value.label
        this.submitData.sex = value.value
      }
      this.sexShow = false
    },
    // 隔离时间选择
    isolateTimeConfirm(value) {
      if (value) {
        this.selectMsg.isolateTime = formatterDate(value)
        this.submitData.isolateTime = formatterDate(value)
      }
      this.isolateTimeShow = false
    },
    userTypeConfirm (value) {
      if (value) {
        this.selectMsg.userTypeStr = value.label
        this.submitData.userType = value.value
      }
      this.userTypeShow = false
    },
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.submitData.id = this.$route.query.id
    this.submitData.userId = this.$route.query.userId
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.box {
  //padding-top: 100px;
  .cont {
    padding: 20px;
    background-color: #f5f5f5;
    .van-cell {
      margin-bottom: 30px;

      .title {
        margin: 0;
        padding: 0;
      }
      .itemCont {
        padding: 0;
      }
    }

    ul {
      width: 100%;
      height: 100%;

      li {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 20px;
      }
    }

    .widthImg {
      height: 100%;

      img {
        width: 90px;
        height: 90px;
      }
    }
  }
}

.van-field__control {
  color: #ccc;
}
h3 {
  margin: 0 0 20px 0;
}
</style>
